.icon {
  --cosmos-icon-size: 20px;
}

/* Visually Hidden, 
   todo: consider making a Visually Hidden component */
.sr-only {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}
